import { useEffect, useState } from "react";
import dynamic from "next/dynamic";
import { S3Client } from "@aws-sdk/client-s3";
import { getApp, initializeApp, getApps } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";
import { getSession } from "@auth0/nextjs-auth0";
import { useAppContext } from "../context/state";
import plavedLogo from "../Assets/images/plavedLogo.png";
import { Button } from "../Components/Common/Button";
import { useRouter } from "next/router";

// Dynamically imported components
const Home = dynamic(() => import("./home"));

// Environment variables for sensitive information
export const REGION = process.env.NEXT_PUBLIC_AWS_REGION;
export const S3_BUCKET = process.env.NEXT_PUBLIC_S3_BUCKET;
export const S3_BUCKET_PRIVATE = process.env.NEXT_PUBLIC_S3_BUCKET_PRIVATE;

// S3 Client initialization for reduced bundle size
export const defaultBucket = new S3Client({
  region: REGION,
  credentials: {
    accessKeyId: process.env.NEXT_PUBLIC_AWS_ACCESS_KEY,
    secretAccessKey: process.env.NEXT_PUBLIC_AWS_SECRET_KEY,
  },
});

// Firebase app initialization with efficient imports
const firebaseConfig = {
  apiKey: "AIzaSyCi2nB3YvGcz-F-W4Pz-jY1y6iHKCqdIVs",
  authDomain: "plaved-survey.firebaseapp.com",
  databaseURL:
    "https://plaved-survey-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "plaved-survey",
  storageBucket: "plaved-survey.appspot.com",
  messagingSenderId: "156044130027",
  appId: "1:156044130027:web:d1acead81b98d014f330ae",
  measurementId: "G-3JZNPWBHWF",
};

function createFirebaseApp(config) {
  if (!getApps().length) {
    return initializeApp(config);
  } else {
    return getApp();
  }
}

const firebaseApp = createFirebaseApp(firebaseConfig);
const database = getDatabase(firebaseApp);

// Inicializar Firebase Auth
const auth = getAuth(firebaseApp);

// Nuevo componente NotLoggedIn con estilos mejorados
const NotLoggedIn = () => {
  const router = useRouter();
  const [currentDateTime, setCurrentDateTime] = useState(null);

  useEffect(() => {
    // Establecer la fecha y hora una vez después de montar el componente
    setCurrentDateTime(new Date());
  }, []);

  return (
    <div className="flex items-center justify-center min-h-screen bg-plaved">
      <div className="bg-white p-4 rounded-[25px] shadow-md w-3/4 max-w-md min-h-72 mx-2 flex items-center justify-center">
        <div className="flex flex-col items-center justify-center space-y-6">
          {/* Logo de PLAVED */}
          <img src={plavedLogo.src} className="w-48 h-auto" alt="PLAVED Logo" />
          {/* Fecha y hora en vivo */}
          {currentDateTime && (
            <h1 className="text-lg font-bold text-center text-plaved">
              {currentDateTime.toLocaleString("es-ES")}
            </h1>
          )}
          <div className="flex flex-col justify-center items-center">
            <Button
              primary
              lg
              onClick={() => (window.location.href = "/api/auth/login")}
            >
              Ir a PLAVED ahora
            </Button>
            <p
              onClick={() => router.push("/api/auth/register")}
              className="cursor-pointer text-center mt-2 hover:underline text-sm text-plaved-5"
            >
              Crear cuenta / Registrarse
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

const IndexPage = () => {
  const { setSessionData } = useAppContext();

  useEffect(() => {
    setSessionData({});
  }, [setSessionData]);

  // Ya no necesitamos verificar el session en el componente
  // Simplemente renderizamos NotLoggedIn
  return <NotLoggedIn />;
};

export default IndexPage;

export { auth, database, firebaseApp };

// Modificamos getServerSideProps para manejar la redirección
export async function getServerSideProps(context) {
  const session = await getSession(context.req, context.res);

  if (session?.user) {
    // Usuario autenticado, redirigir a /home
    return {
      redirect: {
        destination: "/home",
        permanent: false,
      },
    };
  }

  // Usuario no autenticado, renderizar la página normalmente
  return {
    props: {},
  };
}